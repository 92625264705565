import { defineStore } from 'pinia';
import axiosInstance from '@/services/axiosInstance';
import { IUser } from '@/types';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000';

export const useUserStore = defineStore('userStore', {
  state: () => ({
    token: null as string | null,
    user: null as IUser | null,
    subscription: null as PushSubscription | null,
  }),
  actions: {
    async setUser(data: { user: IUser, token: string }) {
      this.user = data.user;
      this.token = data.token;
      localStorage.setItem('token', data.token);
      await this.subscribeToPushNotifications();
    },
    setToken(newToken: string) {
      this.token = newToken;
      localStorage.setItem('token', newToken);
    },
    clearUser() {
      this.user = null;
      this.token = null;
      this.subscription = null;
      localStorage.removeItem('token');
    },
    async loadUserFromStorage() {
      const token = localStorage.getItem('token');
      if (token) {
        this.token = token;
        await this.fetchUserData();
      }
    },
    async fetchUserData() {
      try {
        console.log('Načítám uživatelská data');
        const userId = this.getUserId;
        if (!userId) {
          throw new Error('User ID is not available');
        }
        const response = await axiosInstance.get(`${API_BASE_URL}/users/${userId}`);
        this.user = response.data;
        // Po úspěšném načtení uživatelských dat přihlásíme k push notifikacím
        //if (this.isMobileDevice()) {
          await this.subscribeToPushNotifications();
        //}
      } catch (error) {
        console.error('Chyba při načítání uživatelských dat:', error);
        this.clearUser();
      }
    },
    async subscribeToPushNotifications() {
      const publicKey = 'BFxWiuP7ktkwl13lJARYNsK9Z54eIMfJFf9GvehQr3egadRlqhvnhpuIf6G-TOEaTi_WyUn7YyFdMKYvAVstdaM';
      console.log('VAPID Public Key:', publicKey);

      if (!publicKey) {
        console.error('VAPID veřejný klíč není definován.');
        return;
      }
      const convertedVapidKey = this.urlBase64ToUint8Array(publicKey);
      console.log('Převedený VAPID klíč:', convertedVapidKey);

      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Service Worker je připraven:', registration);

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey,
        });
        this.sendSubscriptionToServer(subscription);

        console.log('Push subscription byla úspěšně vytvořena:', subscription);
      } else {
        console.warn('Service Worker není podporován tímto prohlížečem.');
      }
    },
    async sendSubscriptionToServer(subscription: PushSubscription) {
      try {
        console.log('Odesílám subscription na server:', subscription);
        if (!this.user || !this.user._id) {
          throw new Error('User is not authenticated.');
        }
        await axiosInstance.post(`${API_BASE_URL}/users/notification-subscribe`, {
          subscription,
          userId: this.user._id,
        });
      } catch (error) {
        console.error('Chyba při odesílání subscription na server:', error);
      }
    },
    urlBase64ToUint8Array(base64String: string) {
      const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    isMobileDevice() {
      return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    getUserName: (state) => state.user?.name || '',
    getUserId: (state) => state.user?._id || '',
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'userStore',
        storage: localStorage,
      },
    ],
  },
});
