<template>
  <v-app>
    <!-- Zobrazení MainMenu a Footer pouze pokud cesta není /register -->
    <MainMenu v-if="!hideLayout" />

    <router-view />


    <Footer v-if="!hideLayout" />


    <!-- Přidání LoginOverlay komponenty -->
    <LoginOverlay :show="appStore.isLoginVisible" @close="appStore.closeLoginOverlay" @login="handleLogin" />
  </v-app>
</template>


<script lang="ts" setup>
import { onMounted, computed } from 'vue';
import { useAppStore } from './stores/app/appStore';
import { useUserStore } from '@/stores/user/userStore';
import { useRoute, useRouter } from 'vue-router';
import LoginOverlay from '@/components/LoginOverlay.vue'; // Import LoginOverlay komponenty

const appStore = useAppStore();
const userStore = useUserStore();
const route = useRoute(); // Získání aktuální cesty
const router = useRouter(); // Získání routeru

// Funkce pro zpracování přihlašovacích údajů
const handleLogin = (loginData: { email: string; password: string }) => {
  //console.log('Přihlášený uživatel:', loginData);
  appStore.closeLoginOverlay();
};

// Výpočetní vlastnost pro kontrolu, zda skrýt MainMenu a Footer
const hideLayout = computed(() => {
  const hideRoutes = ['/register', '/login', '/special-route', '/password'];
  return hideRoutes.includes(route.path);
});



onMounted(async () => {
  try {

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'navigate' && event.data.url) {
          router.push(event.data.url);
        }
      });
    }

    userStore.loadUserFromStorage(); // Načítání dat o uživateli při načtení aplikace
    appStore.connect();

  } catch (error) {
    console.error('Chyba při nastavování push notifikací:', error);
  }
});
</script>



<style>
.leaflet-bottom .leaflet-control {
  visibility: hidden;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}

.v-card.ice-card {
  border-radius: 15px !important;
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* Pro Safari */
  color: var(--v-mhblue-base);
}

.v-btn.corner-add-button {
  position: fixed !important;
  bottom: 10px !important;
  right: 20px;
  z-index: 1000;
}


html,
body,
#app {
  background-color: white;
  /* Nastavte barvu podle vašeho přání */
}

.minimal-select.v-field__input {
  padding: 0 !important;
  margin: 0 !important;
  height: 32px;
  /* Výška inputu */
  line-height: 32px;
  /* Vertikální centrování textu */
}
</style>
